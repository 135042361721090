import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Handstand Holds & Walks`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`70ft Sandbag Bear Crawls (50`}{`#`}{`)`}</p>
    <p>{`20 D Ball Slams (30/20`}{`#`}{`)(RX+ 40/30`}{`#`}{`)`}</p>
    <p>{`rest 2:00 then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`5 Wall Walks`}</p>
    <p>{`25 KBS’s (53/35)(RX+ 70/53)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      